import React, {Component} from 'react';
import styled, { css } from "styled-components"
import {media} from "utils/Media"
import {Container, ModalBody} from "reactstrap";

import BgXs from "../../images/HomePage/bg-grad-576.svg"
import BgMd from "../../images/HomePage/bg-grad-991.svg"
import BgXl from "../../images/HomePage/bg-grad-1920.svg"
import PreviewModal from '../shared/PreviewModal'
import RelaxedPerformancesModal from '../shared/RelaxedPerformancesModal'
import ChristmasModal from '../ChristmasModal/ChristmasModal';

const Bg = styled.div`
    background: url("${BgXs}") no-repeat center center;
    background-size: cover;
    padding: 2rem 0;
    
    @media ${media.sm} {
        background: url("${BgMd}") no-repeat center center;
        background-size: cover;
        padding: 3rem 2rem;
    }
    
    @media ${media.xl} {
        background: url("${BgXl}") no-repeat center center;
        background-size: cover;
    }
`

const Content = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    
    .narrow {
        max-width: 800px;
    }
    
    h2 {
        font-family: ${props => props.theme.font.family.special};
        color: ${props => props.theme.colors.navy};
        margin: 0;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;   
    }
`

const BoxWrapper = styled.div`
${props => props.mt && css`
    margin-top: 2rem;
`}
    @media ${media.xl} {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-items: stretch;
    }
    
    .box {
        background: linear-gradient(180deg, #7BF6EF 0%, #FFFFFF 50%);
        width: 100%;
        text-align: center;
        padding: 1rem;
        
        &:nth-child(1) {
            margin-bottom: 2rem;
            
            @media ${media.xl} {
                margin-bottom: 0;
            }
        }
        
        @media ${media.sm} {
            padding: 2rem;
        }
        
        @media ${media.xl} {
            width: 48%;
        }
        
        h3 {
            font-family: ${props => props.theme.font.family.special};
            color: ${props => props.theme.colors.navy};
            font-size: 24px;
            margin-bottom: 1rem;
            line-height: 100%;
            
            @media ${media.sm} {
                font-size: 30px;
            }
            
        }
        
        p {
            max-width: 450px;
        }
    }
`

class PerformanceSchedule extends Component {
    constructor(props) {
        super(props);

        this.state = {
            previewModalOpen: false,
            relaxedPerformancesModalOpen: false,
            christmasModalOpen: false,
        }
    }
    toggleChristmasModal = () => {
        this.setState({christmasModalOpen: !this.state.christmasModalOpen})
    }
    togglePreviewModal = (e, modal) => {
        e.preventDefault();

        this.setState({
            [modal]: !this.state[modal]
        });
    }
    render() {
        return (
            <Bg id={this.props.id}>
                <Container fluid={true}>
                    <Container>
                            <Content>
                                <h2 className="mb-3 text-center">Performance Schedule</h2>
                                <p className="my-3 font-weight-bold text-center">Wed 7pm | Thu 2pm & 7pm | Fri 7pm</p>
                                <p className="my-3 font-weight-bold text-center">Sat 2:30pm & 7pm | Sun 1pm & 5:30pm</p>
                                <p className="mt-3 text-center smaller">From 28 February 2024: Wed, Thurs and Sat matinees 2pm | Thu – Sat 7pm | Sun 1pm & 5.30pm</p>
    
                                <p className=" text-center smaller">From 20 March 2024: Wed, Thurs and Sat matinees 2.30pm | Thu – Sat 7pm | Sun 1pm & 5.30pm</p>

                                <BoxWrapper>

                                    <div className="box narrow mx-auto">
                                        <h3>Access & Relaxed Performances</h3>
                                        <button
                                            onClick={(e) => this.togglePreviewModal(e, "relaxedPerformancesModalOpen")}
                                            className="btn btn--new d-flex mx-auto text-uppercase"><span>See schedule</span></button>
                                        <p  className="smaller text-center mx-auto mt-2">For further information on&nbsp;all&nbsp;accessible performances, please&nbsp;<a href="https://frozenthemusical.co.uk/access/" target="_blank" rel="noopener noreferrer">click here.</a></p>
                                        
                                    </div>
                                </BoxWrapper>

                                {/* <BoxWrapper mt>
                                    <div className="box narrow mx-auto">
                                        <h3>View Christmas Schedule</h3>
                                        <button
                                            onClick={() => this.toggleChristmasModal(true)}
                                            className="btn btn--new d-flex mx-auto text-uppercase"><span>See schedule</span></button>
                                    </div>
                                </BoxWrapper> */}
                            </Content>
                        {/*</ScrollAnimation>*/}
                    </Container>
                </Container>
                <PreviewModal toggleModal={(e) => this.togglePreviewModal(e, "previewModalOpen")} modalOpen={this.state.previewModalOpen}/>
                <ChristmasModal toggleChristmasModal={this.toggleChristmasModal} christmasModalOpen={this.state.christmasModalOpen}/>
                <RelaxedPerformancesModal toggleModal={(e) => this.togglePreviewModal(e, "relaxedPerformancesModalOpen")} modalOpen={this.state.relaxedPerformancesModalOpen}/>
            </Bg>
        );
    }
}

export default PerformanceSchedule;
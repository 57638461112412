import React, {Component} from "react"
import styled from 'styled-components'
import {Container, Row, Col} from 'reactstrap'
import {graphql, Link, StaticQuery} from 'gatsby'
import VideoButton from "components/shared/VideoButton"
import Layout from "components/Layout/Layout"
import PerformanceSchedule from "../components/TicketInfoPage/PerformanceSchedule"
import Map from "../components/HomePage/Map"
import VideoModal from "components/shared/VideoModal"
import TopBar from "../components/shared/TopBar";
import {media} from "utils/Media"
import {GatsbyImage} from "gatsby-plugin-image";
import LazyLoad from 'react-lazyload';
import snowFlakesXs from "../images/snowflakes-mobile.svg"
import snowFlakesXl from "../images/snowflakes-desktop.svg"
import {handleLinkClick} from "../js/handleLinkClick";
import NewBookTickets from "../components/shared/NewBookTickets"

const scrollToElement = require('scroll-to-element')

const PageWrapper = styled.div`
  .enhance {
    text-align: center;

    @media ${media.lg} {
      text-align: left;
    }
  }

  .buttonWrapper {
    justify-content: center;
    margin-bottom: 0;

    @media ${media.lg} {
      justify-content: left;
    }
  }

  .fix-height {

    @media (min-width: 1200px) {
      min-height: 350px;
    }

    @media (min-width: 1400px) {
      min-height: 380px;
    }
    @media (min-width: 1500px) {
      min-height: 400px;
    }
  }

  h2 {
    font-family: ${props => props.theme.font.family.special};
    color: ${props => props.theme.colors.navy};
    margin: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`
const SnowFlakes = styled.div`
  background: url("${snowFlakesXs}") repeat 50% 120%;
  background-size: 240%;

  @media ${media.sm} {
    background: url("${snowFlakesXl}") repeat 50% 70%;
    background-size: 290%;
  }

  @media ${media.md} {
    background: url("${snowFlakesXl}") repeat 50% 70%;
    background-size: 190%;
  }

  @media ${media.lg} {
    background: url("${snowFlakesXl}") repeat 50% 70%;
    background-size: 190%;
  }

  @media ${media.xl} {
    background: url("${snowFlakesXl}") no-repeat 50% 70%;
    background-size: 180%;
  }

  .video {
    max-width: 600px;
    margin: 0 auto;
  }
`

const VideoWrapper = styled.div`
  position: relative;
  z-index: 2;
  max-width: 922px;
  margin: 3rem auto;
`

const Query = () => (
  <StaticQuery
    query={graphql`
            query {
                FallbackXs: file(relativePath: { eq: "HomePage/header-vid-ph-576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }

                FallbackSm: file(relativePath: { eq: "HomePage/header-vid-ph-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }

                FallbackXl: file(relativePath: { eq: "HomePage/header-vid-ph-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                CloseApp: file(relativePath: { eq: "HomePage/close-app-phone.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 551, quality: 100)
                    }
                }

                videoPlaceholder2: file(relativePath: { eq: "relaxed-perf-580x387.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1200, quality: 100)
                    }
                }
            }
		`}
    render={data => (
      <TicketInfoPage data={data}/>
    )}
  />
)

class TicketInfoPage extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      modalOpen: false,
      // src: "https://cdnapisec.kaltura.com/p/1068292/sp/106829200/embedIframeJs/uiconf_id/40058201/partner_id/1068292?iframeembed=true&playerId=kaltura_player&entry_id=1_54agprxu&flashvars&#91;localizationCode&#93;=en&&wid=1_btrvbqh7",
      showImages: false
    }
  }
  
  
  toggleModal = (e, title, src) => {
    e.preventDefault();
    
    this.setState({
      src: src,
      title: title,
      modalOpen: !this.state.modalOpen
    });
  }
  
  onClick = () => {
    scrollToElement('#bottomOfIframe', {
      duration: 300
    });
  }
  
  render() {
    
    return (
      <Layout pageName="ticket-information" title="Ticket Information" slug="ticket-information">
        <PageWrapper>
          <TopBar title="Ticket Information"/>
          <SnowFlakes>
            <NewBookTickets main magical fourOrMore />
            <PerformanceSchedule/>
            
            <LazyLoad>
              <Container fluid={true} className="no-padding-xs">
                <Container>
                  <Row>
                    <Col xs={12} className="my-auto">
                      <hr className="mt-5"/>
                      <h2 className="mb-3 pt-4 text-center">Relaxed Performance</h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={6} className="my-auto">
                      <VideoWrapper>
                        <VideoButton
                          onClick={(e) => this.toggleModal(e, "Relaxed Performances at Disney's Frozen", "https://secure.disney.com/embed/5ed33e374e0f4ebd6b4f2a72?domain=www.disney.co.uk")}
                          aria-label="Open video">
                          <GatsbyImage
                            image={this.props.data.videoPlaceholder2.childImageSharp.gatsbyImageData}
                            alt="Relaxed Performances at Disney's Frozen"
                            title="Relaxed Performances at Disney's Frozen"
                            className="img-fluid d-block"
                          />
                        </VideoButton>
                      </VideoWrapper>
                    </Col>
                    <Col xl={6} className="my-auto pt-4 pt-xl-0">
                      <p className="mb-4 enhance color--black text-center">FROZEN is committed
                        to creating a friendly and inclusive audience experience.</p>
                      <p className="mb-4 enhance color--black text-center">Relaxed Performances are designed to provide
                        an opportunity for people who are autistic, neurodiverse, or would benefit from reduced sensory
                        input and might prefer a more relaxed environment. There are extra trained staff on hand, and
                        dedicated quiet areas inside the theatre should anyone need to leave their seat.</p>
                      <p className="mb-4 enhance color--black text-center">For further
                        information on accessible performances, please <Link to="/access/" className="link">click
                          here</Link></p>
                          <p  className=" text-center mx-auto mt-2">Our next relaxed performance will take place on 28 April, 12.30pm.</p>
                                        <a className="no-interstitial-check" href="https://ticketing.lwtheatres.co.uk/event/234/performance/48231?PROMO=FRZRLXPERF" target="_blank" rel="noopener noreferrer">

                                        <button
                                            className="btn btn--new d-flex mx-auto text-uppercase"><span>Book Now</span></button>
                                            </a>
                      {/* <div className="d-flex buttonWrapper">
                        <a
                          href="https://booking.lwtheatres.co.uk/event/2c62fa9a-9025-ea11-a9ca-064c84cbe4ea/performance/d726bab6-5922-4d92-838c-039762ccc61f"
                          className="btn btn--new d-flex mx-auto text-uppercase"
                          onClick={(e) => handleLinkClick(e)}
                        ><span>BOOK NOW</span></a>
                      </div> */}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className="my-auto">
                      <hr className="mt-5"/>
                    </Col>
                  </Row>
                </Container>
              </Container>
            </LazyLoad>
            
            <LazyLoad>
              <Map/>
            </LazyLoad>
          </SnowFlakes>
          <VideoModal toggleModal={this.toggleModal} title={this.state.title} src={this.state.src}
                      modalOpen={this.state.modalOpen}/>
        </PageWrapper>
      </Layout>
    )
  }
}

export default Query
